import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
`;

export const Panel = styled.div`
  background-color: #ffffff;
  border-radius: 18px;
  padding: 25px;
`;
