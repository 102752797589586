import React, { useEffect, useRef, useState } from 'react';
import yup from 'utils/validation';
import { ButtonsDiv, Container, Divider, FormRow, InputField, LabelCheck, SignUpForm } from './styles';
import Button from 'components/Buttom/Button';
import Input from 'components/Input/Input';
import FieldMask from 'components/FieldMask';
import ErrorMessage from 'components/ErrorMessage';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import { useHistory } from 'react-router';
import { MenuItem, Select } from '@mui/material';
import AuthService from 'services/AuthService';
import InputPassword from 'common/InputPassword/InputPassword';

const initialValues = {
  document: '',
  email: '',
  name: '',
  password: '',
  extraInfo: {
    registration: '',
    tradeName: '',
  },
};

interface Props {
  handleSubmit(type: string, data: any): Promise<void>;
}

const SignUpNews = (props: Props): JSX.Element => {
  const form = useRef<FormikProps<FormikValues>>(null);
  const [show, setShow] = useState(true);
  const [seturJournalist, setSeturJournalist] = useState(false);
  const { handleSubmit } = props;

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        document.getElementById('hiddenSubmitButton')?.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleChange = (e: any) => {
    // @TODO clear related field (registration or tradeName)
    // @TODO do not allow to next without filling out
    setSeturJournalist(e.target.value);
  };

  const submit = async (data: any) => {
    await handleSubmit('news', data);
  };

  const validationSchema = yup.object({
    document: yup.string().length(14).required(),
    email: yup.string().email().required(),
    name: yup.string().required(),
    password: yup.string().min(8).required(),
    extraInfo: yup.object({
      ...(seturJournalist && { registration: yup.string().required() }),
      ...(!seturJournalist && { tradeName: yup.string().required() }),
    }),
  });

  return (
    <Formik innerRef={form} initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
      <>
        {show && (
          <Container>
            <div>
              <h2>Preencha as informações abaixo</h2>
              <span>1/2</span>
            </div>
            <label htmlFor="">
              Você é jornalista da SETUR? <span>*</span>
            </label>
            <Select className="dropdown" onChange={e => handleChange(e)}>
              <MenuItem value={1}>Sim</MenuItem>
              <MenuItem value={0}>Não</MenuItem>
            </Select>
            <Button text="PRÓXIMO" onClick={() => setShow(false)} />
          </Container>
        )}
        {!show && (
          <SignUpForm>
            <header>
              <h2>Preencha as informações abaixo:</h2>
              <span>2/2</span>
            </header>
            <Form>
              <FormRow>
                <InputField>
                  <div className="NameField">
                    <label htmlFor="">
                      Nome Completo<span>*</span>
                    </label>
                    <Field name="name" />
                  </div>
                  <ErrorMessage name="name" />
                </InputField>
              </FormRow>
              <FormRow>
                <InputField>
                  <div>
                    <label htmlFor="">
                      CPF<span>*</span>
                    </label>
                    <FieldMask type="cpf">
                      <Field name="document" />
                    </FieldMask>
                  </div>
                  <ErrorMessage name="document" />
                </InputField>
                {seturJournalist ? (
                  <InputField>
                    <div>
                      <label htmlFor="">
                        Matrícula<span>*</span>
                      </label>
                      <Field name="extraInfo.registration" />
                    </div>
                    <ErrorMessage name="extraInfo.registration" />
                  </InputField>
                ) : (
                  <InputField>
                    <div>
                      <label htmlFor="">
                        Nome Fantasia da Empresa
                        <span>*</span>
                      </label>
                      <Field name="extraInfo.tradeName" />
                    </div>
                    <ErrorMessage name="extraInfo.tradeName" />
                  </InputField>
                )}
              </FormRow>
              <FormRow>
                <InputField>
                  <div>
                    <label htmlFor="">
                      E-mail<span>*</span>
                    </label>
                    <Field name="email" />
                  </div>
                  <ErrorMessage name="email" />
                </InputField>
                <InputField>
                  <div>
                    <label htmlFor="">
                      Senha<span>*</span>
                    </label>
                    <InputPassword name={'password'} />
                    <ErrorMessage name="password" />
                  </div>
                </InputField>
              </FormRow>
              <Divider />
              {/* <LabelCheck>
                <Input type="checkbox" />
                <label htmlFor="">
                  Aceito os
                  <a href="">
                    <strong>Termos de Uso</strong>
                  </a>
                </label>
              </LabelCheck>
              <LabelCheck>
                <Input type="checkbox" />
                <label htmlFor="">
                  Aceito a
                  <a href="">
                    <strong>Política de Privacidade</strong>
                  </a>
                </label>
              </LabelCheck>
              <LabelCheck>
                <Input type="checkbox" checked />
                <label htmlFor="">Desejo receber a newsletter em meu e-mail</label>
              </LabelCheck> */}
              <ButtonsDiv>
                <Button onClick={() => setShow(true)} text="VOLTAR" />
                <Button
                  onClick={() => {
                    setShow(true);
                    form?.current?.setValues(initialValues);
                  }}
                  text="CANCELAR"
                />
                <Button type="submit" text="CRIAR" />
                <button id="hiddenSubmitButton" type="submit" style={{ display: 'none' }} />
              </ButtonsDiv>
            </Form>
          </SignUpForm>
        )}
      </>
    </Formik>
  );
};

export default SignUpNews;
