export default interface INews {
  id: number;
  title: string;
  description: string;
  image: string;
  banner: string;
  link: string;
  date: string;
  subtitle?: string;
  author?: string;
  slug?: string;
  images?: Array<{ image: string }>;
}

export enum NewsStatusAPI {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}
