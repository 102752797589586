import styled from 'styled-components';

export const PasswordDiv = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;

  button {
    position: absolute;
    background-color: transparent;
    border: none;
    margin-right: 12px;
    top: 45%;
    img {
      height: 100%;
      width: 100%;
    }
  }
`;
