import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { formatDate } from 'utils/string';
import PublicService from 'services/PublicService';
import fetchPosts, { Post } from 'services/InstagramService';
import INews from 'interfaces/INews';
import Button from 'components/Buttom/Button';
import Card from 'components/Card';
import MainTitle from 'components/MainTitle';
import NewsCard from 'components/NewsCard';
import { Container, Icon, SocialMediaContainer } from './styles';
import { useLoading } from 'hooks/useLoading';
import { Link } from 'react-router-dom';
import { MainArticle } from 'components/NewsCard/styles';
import InstragamPost from 'common/InstagramPost/InstragamPost';

const News = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [posts, setPosts] = useState<Post[]>([]);
  const [articles, setArticles] = useState<INews[]>([]);
  const [mainArticle, setMainArticle] = useState<INews | undefined>();

  const service = new PublicService();

  const getData = async () => {
    try {
      openLoading();
      await Promise.all([getArticles(), getPosts()]);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getArticles = async () => {
    try {
      const [main, ...others] = await service.getAllNews();

      setMainArticle(main);
      setArticles(others);
    } catch (error) {
      console.error('error_to_get_articles');
    }
  };

  const getPosts = async () => {
    try {
      const data = await fetchPosts();
      setPosts(data);
    } catch (error) {
      console.error('error_to_get_post');
    }
  };

  return (
    <Container>
      <MainTitle>Notícias</MainTitle>
      <Box mt={2} sx={{ flexGrow: 1 }}>
        <Grid container className="news-section">
          <Grid item mb={6} xs={12} className="news-grid">
            {mainArticle && (
              <Link to={`news/${mainArticle.slug}`}>
                <MainArticle>
                  <NewsCard
                    title={mainArticle.title}
                    date={formatDate(mainArticle.date)}
                    link={`news/${mainArticle.slug}`}
                    description={mainArticle.description}
                    image={mainArticle.image}
                  />
                </MainArticle>
              </Link>
            )}
          </Grid>
          <Grid container columnSpacing={3}>
            {articles?.map((article, index) => (
              <Grid key={index} columnGap={2} mb={4} xs={12} md={6} xl={6} item className="news-group">
                <Link to={`news/${article.slug}`} style={{ width: '100%' }}>
                  <NewsCard
                    title={article.title}
                    date={formatDate(article.date)}
                    link={`news/${article.slug}`}
                    description={article.description}
                    image={article.image}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <MainTitle>Mídias Sociais</MainTitle>

        <SocialMediaContainer>
          <ul>
            {posts.map(post => {
              return <InstragamPost key={post.id} post={post} />;
            })}
          </ul>
        </SocialMediaContainer>

        <Grid container spacing={4} className="news-section">
          <Grid item xs={12} md={6} className="buttons-section">
            <Grid item xs={12} className="button-div">
              <a href="https://www.instagram.com/portoseguroturismo/" target="_blank" rel="noreferrer">
                <Button>
                  <Icon className="instagram-logo" />
                  @portoseguroturismo
                </Button>
              </a>
            </Grid>
            <Grid item xs={12} className="button-div">
              <a href="https://www.youtube.com/@portoseguroturismo3631" target="_blank" rel="noreferrer">
                <Button>
                  <Icon className="youtube-logo" />
                  @portoseguroturismo3631
                </Button>
              </a>
            </Grid>
            <Grid item xs={12} className="button-div">
              <a href="https://www.tiktok.com/@psturismo" target="_blank" rel="noreferrer">
                <Button>
                  <Icon className="tiktok-logo" />
                  @psturismo
                </Button>
              </a>
            </Grid>
            <Grid item xs={12} className="button-div">
              <a href="https://www.facebook.com/PortoSeguroTur" target="_blank" rel="noreferrer">
                <Button>
                  <Icon className="facebook-logo" />
                  /PortoSeguroTur
                </Button>
              </a>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className="video-section">
            <Card>
              <iframe
                title="YouTube Video"
                src="https://www.youtube.com/embed/fSI5QKgxgJE?si=2OxgKGTSql3_2574"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default News;
