import React from 'react';
import { Container, ContentDiv, Header } from './styles';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

interface Props {
  setProfile: React.Dispatch<React.SetStateAction<number>>;
}

const SelectProfile = (props: Props): JSX.Element => {
  const { setProfile } = props;
  const path = window.location.pathname;

  const handleSignUpRadioGroup = () => {
    return path.includes('tourist') ? (
      <RadioGroup
        defaultValue="0"
        className="radioGroup"
        onChange={e => {
          setProfile(parseInt(e.target.value));
        }}>
        <FormControlLabel value="0" control={<Radio />} label="Turista" checked />
      </RadioGroup>
    ) : (
      <RadioGroup
        defaultValue="0"
        className="radioGroup"
        onChange={e => {
          setProfile(parseInt(e.target.value));
        }}>
        <FormControlLabel value="0" control={<Radio />} label="Imprensa" />
        {/* <FormControlLabel value="1" control={<Radio />} label="Organizador de Excursão" /> */}
        <FormControlLabel value="2" control={<Radio />} label="Secretaria do Turismo - SETUR" />
        <FormControlLabel value="3" control={<Radio />} label="Trade" />
      </RadioGroup>
    );
  };

  const handleSignInRadioGroup = () => {
    return path.includes('tourist') ? (
      <RadioGroup
        defaultValue="0"
        className="radioGroup"
        onChange={e => {
          setProfile(parseInt(e.target.value));
        }}>
        <FormControlLabel value="0" control={<Radio />} label="Turista" checked />
      </RadioGroup>
    ) : (
      <RadioGroup
        defaultValue="0"
        className="radioGroup"
        onChange={e => {
          setProfile(parseInt(e.target.value));
        }}>
        <FormControlLabel value="0" control={<Radio />} label="Imprensa" />
        {/* <FormControlLabel value="1" control={<Radio />} label="Organizador de Excursão" /> */}
        <FormControlLabel value="2" control={<Radio />} label="Secretaria do Turismo - SETUR" />
        <FormControlLabel value="3" control={<Radio />} label="Trade" />
      </RadioGroup>
    );
  };
  return (
    <Container>
      <Header>
        <div />
        <h3>{path.includes('signup') ? 'Criar Conta' : 'Iniciar Sessão'}</h3>
        <div />
      </Header>
      <ContentDiv>
        <label>Selecione o seu tipo de perfil:</label>
        {path.includes('signup') ? handleSignUpRadioGroup() : handleSignInRadioGroup()}
      </ContentDiv>
    </Container>
  );
};

export default SelectProfile;
