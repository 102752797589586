import React, { useState } from 'react';
import { ButtonSection, Container, Content, SelectLoginDiv, SuccessModal } from './styles';
import SelectProfile from 'common/SelectProfile';
import SignUpNews from 'features/Signup/pages/SignupNews';
import SignupTourOrganizer from 'features/Signup/pages/SignupTourOrganizer/';
import SignUpSETUR from 'features/Signup/pages/SignupSETUR';
import SignUpTrade from 'features/Signup/pages/SignupTrade';
import { Modal } from '@mui/material';
import Button from 'components/Button';
import AuthService from 'services/AuthService';
import { useHistory } from 'react-router';
import { useLoading } from 'hooks/useLoading';

const SignUpAdmin = (): JSX.Element => {
  const history = useHistory();
  const [profile, setProfile] = useState(0);
  const [modalStatus, setModalStatus] = React.useState({} as any);
  const { openLoading, closeLoading } = useLoading();

  const showModal = (name: string) => {
    setModalStatus({ ...modalStatus, [name]: true });
  };
  const closeModal = (name: string) => {
    setModalStatus({ ...modalStatus, [name]: false });
  };

  const handleSubmit = async (type: string, data: any) => {
    try {
      openLoading();
      const service = new AuthService();
      await service.signUp(type, data);
      showModal('success');
      closeLoading();
    } catch (err: any) {
      closeLoading();
      if (err?.response?.status === 409 || err?.code === 'auth/email-already-in-use') {
        showModal('already-exists');
        return;
      }
      showModal('error');
    }
  };

  const handleProfile = () => {
    switch (profile) {
      case 0:
        return <SignUpNews handleSubmit={handleSubmit} />;
      // case 1:
      //   return <SignupTourOrganizer handleSubmit={handleSubmit} />;
      case 2:
        return <SignUpSETUR handleSubmit={handleSubmit} />;
      default:
        return <SignUpTrade handleSubmit={handleSubmit} />;
    }
  };
  return (
    <Container>
      <Content>
        <SelectProfile setProfile={setProfile} />
        <SelectLoginDiv>{handleProfile()}</SelectLoginDiv>
      </Content>
      <Modal open={Boolean(modalStatus['success'])} onClose={() => closeModal('success')} id="success-signup-modal">
        <SuccessModal>
          <h2>Seu cadastro foi realizado com sucesso!</h2>
          <span>Inicie uma sessão para conhecer Porto Seguro com sua conta:</span>
          <Button buttonType="primary" onClick={() => history.push('/login/admin')}>
            INICIAR SESSÃO
          </Button>
        </SuccessModal>
      </Modal>
      <Modal open={Boolean(modalStatus['already-exists'])} onClose={() => closeModal('already-exists')}>
        <SuccessModal>
          <h2>Conta existente</h2>
          <span>
            O e-mail, CPF ou CNPJ preenchido está vinculado a uma conta existente. Entre em <b>Iniciar Sessão</b> para
            se logar ou clique em <b>Esqueci Minha Senha</b> para recuperar a conta.
          </span>
          <ButtonSection>
            <Button buttonType="secondary" onClick={() => closeModal('already-exists')}>
              FECHAR
            </Button>
            <Button buttonType="primary" onClick={() => history.push('/login/admin')}>
              INICIAR SESSÃO
            </Button>
          </ButtonSection>
        </SuccessModal>
      </Modal>
      <Modal open={Boolean(modalStatus['error'])} onClose={() => closeModal('error')}>
        <SuccessModal>
          <h2>ERRO</h2>
          <span>Não foi possível completar o cadastro, tente novamente mais tarde!</span>
          <Button buttonType="primary" onClick={() => closeModal('error')}>
            FECHAR
          </Button>
        </SuccessModal>
      </Modal>
    </Container>
  );
};

export default SignUpAdmin;
