import React, { forwardRef, Ref } from 'react';
import { MaskProps, useMask } from '@react-input/mask';

export type MaskType = 'cnpj' | 'cpf' | 'cnpj-or-cpf' | 'date' | 'phone' | 'zipcode' | 'time' | 'currency';
type IMaskOptions = { [key in MaskType]: MaskProps };

interface IProps {
  type: MaskType;
  children: JSX.Element;
  id?: string;
}

const maskOptions: IMaskOptions = {
  cnpj: { mask: '__.___.___/____-__', replacement: { _: /\d/ } },
  cpf: { mask: '___.___.___-__', replacement: { _: /\d/ } },
  date: { mask: '__/__/____', replacement: { _: /\d/ } },
  time: { mask: '99:99', replacement: { 9: /\d/ } },
  phone: { mask: '(__) _ ____-____', replacement: { _: /\d/ } },
  zipcode: { mask: '_____-___', replacement: { _: /\d/ } },
  'cnpj-or-cpf': {
    mask: '$'.repeat(18),
    replacement: { _: /\d/, $: /\d/ },
    modify: ({ length }: string) => {
      const cpf = '___.___.___-__$';
      const cnpj = '__.___.___/____-__';
      return { mask: length <= 11 ? cpf : cnpj };
    },
  },
  currency: {
    mask: '_',
    replacement: { _: /\d/ },
    modify: ({ length }) => {
      const masks = ['_', '0,0__', '0,___', '_,___', '__,___', '___,___', '____,___'];
      const mask = masks[length] || '_____,__';
      return { mask };
    },
  },
};

const FieldMask = forwardRef((props: IProps, ref: Ref<any>): React.ReactElement => {
  const { children, type } = props;

  const options = maskOptions[type];
  const innerRef = useMask(options);

  return React.cloneElement(children, { innerRef, ref: innerRef });
});

FieldMask.displayName = 'FieldMask';
export default FieldMask;
