import React from 'react';
import AdminAccount from '../AdminAccount';
import NewsAccount from '../NewsAccount';
import SETURAccount from '../SETURAccount';
import TourOrganizerAccount from '../TourOrganizerAccount';
import TouristAccount from '../TouristAccount';
import TradeAccount from '../TradeAccount';
import { getLocalUser } from 'services/localStorage';
import UserService from 'services/UserService';
import ImageService from 'services/ImageService';
import { AskForEditDataModal, Container, DeleteModal, RenderedForm } from './styles';
import { Modal } from '@mui/material';
import Button from 'components/Buttom/Button';
import { useLoading } from 'hooks/useLoading';

const HandleAccount = (): JSX.Element => {
  const user = getLocalUser();
  const { loading, openLoading, closeLoading } = useLoading();
  const [showModalExclude, setShowModalExclude] = React.useState(false);
  const [showModalAskForExclude, setShowModalAskForExclude] = React.useState(false);
  const [showModalAskForEditData, setShowModalAskForEditData] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const service = new UserService();

  React.useEffect(() => {
    const getUserData = async () => {
      return service.findOne();
    };

    getUserData()
      .then((data: any) => {
        setUserData({ ...data, password: '' });
        closeLoading();
      })
      .catch(() => closeLoading());
  }, []);

  const uploadProfileImage = async (profileImage: File) => {
    const service = new ImageService();
    return service.upload(profileImage);
  };

  const saveUserData = async (data: any) => {
    try {
      openLoading();
      if (data.profileImage) {
        data.profileImage = await uploadProfileImage(data.profileImage);
      }

      await service.update(data);
      setUserData(data);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  const handleDelete = () => {
    console.log('deleting');
    setShowModalExclude(false);
  };

  const handleComponent = () => {
    if (loading) return;

    switch (user?.profile?.name) {
      case 'admin':
        return <AdminAccount userData={userData} saveUserData={saveUserData} />;
      case 'news':
      case 'news-external':
        return (
          <NewsAccount
            setAskForExcludeModal={setShowModalAskForExclude}
            setExcludeModal={setShowModalExclude}
            setShowModal={setShowModalAskForEditData}
            saveUserData={saveUserData}
            userData={userData}
          />
        );
      case 'setur':
      case 'setur-market':
      case 'setur-marketing':
      case 'setur-fundetur':
      case 'setur-attorney':
      case 'setur-excursion':
        return (
          <SETURAccount
            setExcludeModal={setShowModalAskForExclude}
            setShowModal={setShowModalAskForEditData}
            saveUserData={saveUserData}
            userData={userData}
          />
        );
      case 'tour':
        return (
          <TourOrganizerAccount
            setExcludeModal={setShowModalAskForExclude}
            setShowModal={setShowModalAskForEditData}
            saveUserData={saveUserData}
            userData={userData}
          />
        );
      case 'tourist':
        return <TouristAccount setShowModal={setShowModalExclude} saveUserData={saveUserData} userData={userData} />;
      case 'trade':
        return (
          <TradeAccount
            setExcludeModal={setShowModalAskForExclude}
            setShowModal={setShowModalAskForEditData}
            saveUserData={saveUserData}
            userData={userData}
          />
        );
    }
  };

  return (
    <Container>
      <header>
        <div />
        Minha Conta
        <div />
      </header>
      <RenderedForm>{handleComponent()}</RenderedForm>
      <Modal open={showModalExclude} onClose={() => setShowModalExclude(false)} id="success-signup-modal">
        <DeleteModal>
          <h2>EXCLUSÃO DE CONTA</h2>
          <span>
            Você está excluindo uma conta. Se prosseguir com a ação, <strong>não será possível recuperar</strong> essa
            conta depois. Deseja continuar?
          </span>
          <div>
            <Button text="CANCELAR" onClick={() => setShowModalExclude(false)} />
            <Button text="EXCLUIR" onClick={handleDelete} />
          </div>
        </DeleteModal>
      </Modal>
      <Modal open={showModalAskForExclude} onClose={() => setShowModalAskForExclude(true)} id="success-signup-modal">
        <DeleteModal>
          <h2>SOLICITAÇÃO DE EXCLUSÃO DE CONTA</h2>
          <span>
            Você está solicitando a exclusão de uma conta. Se a solicitação for aprovada,
            <strong>não será possível recuperar</strong> essa conta depois. Deseja continuar?
          </span>
          <div>
            <Button text="CANCELAR" onClick={() => setShowModalAskForExclude(false)} />
            <Button text="SOLICITAR EXCLUSÃO" onClick={handleDelete} />
          </div>
        </DeleteModal>
      </Modal>
      <Modal open={showModalAskForEditData} id="success-signup-modal">
        <AskForEditDataModal>
          <h2>SOLICITAÇÃO DE ALTERAÇÃO DE DADO NÃO EDITÁVEL</h2>
          <span>
            Para solicitar a alteração de um dado não editável, entre em contato com a Secretaria do Turismo através do
            e-mail <strong>informacaoturistica@portosegurotur.com.</strong>
          </span>
          <Button text="OK" onClick={() => setShowModalAskForEditData(false)} />
        </AskForEditDataModal>
      </Modal>
    </Container>
  );
};

export default HandleAccount;
