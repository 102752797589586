import Yup from 'utils/validation';
import { businessFormInitialValues } from '../utils';

export const accomodationsInitialValues = {
  ...businessFormInitialValues,
  accommodation: {
    id: '',
    category: '',
    description: '',
    numberOfBeds: '',
    numberOfRooms: '',
  },
};

export const accomodationsValidationSchema = Yup.object().shape({
  id: Yup.number(),
  businessOwner: Yup.array().of(Yup.string().required()),
  businessRepresentative: Yup.string().required(),
  businessType: Yup.string().required(),
  description: Yup.string().required(),
  document: Yup.string().length(18).required(),
  googleMapsLink: Yup.string().required(),
  tradeName: Yup.string().required(),
  title: Yup.string().required(),
  phone: Yup.string().required(),
  site: Yup.string(),
  whatsapp: Yup.string(),
  image: Yup.array().min(1).required(),
  address: Yup.object().shape({
    id: Yup.number(),
    city: Yup.string().required(),
    neighborhood: Yup.string().required(),
    number: Yup.string().required(),
    state: Yup.string().required(),
    street: Yup.string().required(),
    zipCode: Yup.string().required(),
  }),
  images: Yup.array().min(1).required(),
  accommodation: Yup.object().shape({
    id: Yup.string(),
    category: Yup.string().required(),
    numberOfBeds: Yup.string().required(),
    numberOfRooms: Yup.string().required(),
  }),
});
