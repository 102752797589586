import React from 'react';
import { Container } from './styles';
import NewsCard from 'common/NewsCard/NewsCard';
import Button from '../../components/Buttom/Button';
import INews from 'interfaces/INews';
import SubTitle from 'common/SubTitle/SubTitle';
import { useHistory } from 'react-router';
import { formatDate } from 'utils/string';

interface Props {
  news?: INews[];
}

const News = (props: Props): JSX.Element => {
  const { news } = props;
  const history = useHistory();

  return (
    <Container>
      <SubTitle text="Notícias" />
      <ul>
        {news?.slice(0, 2).map(newsCard => {
          return (
            <NewsCard
              key={newsCard.id}
              img={newsCard.image}
              dateStr={formatDate(newsCard.date)}
              title={newsCard.title}
              description={newsCard.description}
              slug={newsCard.slug ?? ''}
            />
          );
        })}
      </ul>
      <Button text="ver todas as notícias" onClick={() => history.push('news')} />
    </Container>
  );
};

export default News;
