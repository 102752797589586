import React from 'react';
import { Container, MainContent } from './styles';
import MainTitle from 'components/MainTitle';
import BusinessList from './components/BusinessList';

const NewsAdmin = (): JSX.Element => {
  return (
    <Container>
      <MainTitle>Minhas Notícias</MainTitle>
      <MainContent>
        <BusinessList />
      </MainContent>
    </Container>
  );
};

export default NewsAdmin;
