import Yup from 'utils/validation';

export const states = [
  '',
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];

export const daysOfWeek = [
  'Domingo',
  'Segunda-Feira',
  'Terça-Feira',
  'Quarta-Feira',
  'Quinta-Feira',
  'Sexta-Feira',
  'Sábado',
];

export const getValidationSchema = (formName: string) => {
  switch (formName) {
    case 'Editar Evento':
    case 'Novo Evento':
      return Yup.object().shape({
        title: Yup.string().required(),
        category: Yup.array().min(1).required(),
        description: Yup.string().required(),
        startDate: Yup.string().required(),
        endDate: Yup.string().required(),
        startTime: Yup.string().required(),
        endTime: Yup.string().required(),
        address: Yup.string().required(),
        placeId: Yup.string().required(),
        place: Yup.string().required(),
        phone: Yup.string().required(),
        price: Yup.string().required(),
        addressInfo: Yup.object().shape({
          zipCode: Yup.string().required(),
          neighborhood: Yup.string().required(),
          number: Yup.string().required(),
          city: Yup.string().required(),
          state: Yup.string().required(),
        }),
        banners: Yup.array().min(1).required(),
        image: Yup.array().min(1).required(),
      });
    case 'Editar Roteiro':
    case 'Novo Roteiro':
      return Yup.object().shape({
        title: Yup.string().required(),
        category: Yup.array().min(1).required(),
        description: Yup.string().required(),
        address: Yup.string().required(),
        placeId: Yup.string().required(),
        addressInfo: Yup.object().shape({
          zipCode: Yup.string().required(),
          neighborhood: Yup.string().required(),
          number: Yup.string().required(),
          city: Yup.string().required(),
          state: Yup.string().required(),
        }),
        images: Yup.array().min(1).required(),
      });
    default:
      return Yup.object().shape({});
  }
};
