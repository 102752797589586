import React, { useRef, useState } from 'react';
import yup from 'utils/validation';
import { ButtonsDiv, FormRow, InputField, LabelCheck, SignUpForm } from './styles';
import Button from 'components/Buttom/Button';
import Input from 'components/Input/Input';
import ArrayField from 'components/ArrayField';
import ErrorMessage from 'components/ErrorMessage';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import InputPassword from 'common/InputPassword/InputPassword';
import FieldMask from 'components/FieldMask';

const initialValues = {
  document: '',
  email: '',
  password: '',
  extraInfo: {
    businessRepresentative: '',
    businessOwner: [''],
    phone: '',
  },
};

interface Props {
  handleSubmit(type: string, data: any): Promise<void>;
}

const SignUpTrade = (props: Props): JSX.Element => {
  const { handleSubmit } = props;
  const [show, setShow] = useState(true);
  const form = useRef<FormikProps<FormikValues>>(null);
  const validationSchema = yup.object({
    document: yup.string().required(),
    email: yup.string().required(),
    password: yup.string().required(),
    extraInfo: yup.object({
      businessOwner: yup.array().of(yup.string().required()),
      businessRepresentative: yup.string().required(),
      phone: yup.string().required(),
    }),
  });

  const submit = async (data: any) => {
    const firstOwner = data.extraInfo.businessOwner[0];
    data.name = firstOwner;
    await handleSubmit('trade', data);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
      <SignUpForm>
        <header>
          <h2>Preencha as informações abaixo:</h2>
          <span>1/1</span>
        </header>
        <Form>
          <FormRow className="full-name-row" id="proper-full-name-row">
            <InputField>
              <div>
                <label htmlFor="">
                  Nome Completo do Proprietário <span>*</span>
                </label>
                <ArrayField name="extraInfo.businessOwner" />
              </div>
            </InputField>
          </FormRow>
          <FormRow className="full-name-row">
            <InputField>
              <div>
                <label htmlFor="">
                  Nome Completo do Responsável Comercial <span>*</span>
                </label>
                <Field name="extraInfo.businessRepresentative" />
              </div>
              <ErrorMessage name="extraInfo.businessRepresentative" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  CPF do Primeiro Proprietário <span>*</span>
                </label>
                <FieldMask type="cpf">
                  <Field name="document" />
                </FieldMask>
              </div>
              <ErrorMessage name="document" />
            </InputField>
            <InputField>
              <div>
                <label htmlFor="">
                  Contato <span>*</span>
                </label>
                <FieldMask type="phone">
                  <Field name="extraInfo.phone" />
                </FieldMask>
              </div>
              <ErrorMessage name="extraInfo.phone" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  E-mail<span>*</span>
                </label>
                <Field name="email" />
              </div>
              <ErrorMessage name="email" />
            </InputField>
            <InputField>
              <div>
                <label htmlFor="">
                  Senha<span>*</span>
                </label>
                <InputPassword name={'password'} />
                <ErrorMessage name="password" />
              </div>
            </InputField>
          </FormRow>
          {/* <LabelCheck>
            <Input type="checkbox" />
            <label htmlFor="">
              Aceito os
              <a href="">
                <strong>Termos de Uso</strong>
              </a>
            </label>
          </LabelCheck>
          <LabelCheck>
            <Input type="checkbox" />
            <label htmlFor="">
              Aceito a
              <a href="">
                <strong>Política de Privacidade</strong>
              </a>
            </label>
          </LabelCheck>
          <LabelCheck>
            <Input type="checkbox" checked />
            <label htmlFor="">Desejo receber a newsletter em meu e-mail</label>
          </LabelCheck> */}
          <ButtonsDiv>
            <Button type="submit" text="CRIAR" />
          </ButtonsDiv>
        </Form>
      </SignUpForm>
    </Formik>
  );
};

export default SignUpTrade;
