import React, { useContext, useRef, useState } from 'react';
import yup from 'utils/validation';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router';
import { ButtonSection, Container, Content, SelectLoginDiv, SuccessModal } from './styles';
import { Modal } from '@mui/material';
import Button from 'components/Buttom/Button';
import SelectProfile from 'common/SelectProfile';
import { getLocalUser, setAccessToken, setLocalUser } from 'services/localStorage';
import AuthService from 'services/AuthService';
import { logged } from 'features/Login/store/LoginAtom';
import AppContext from 'common/AppContext';
import TourOrganizerLogin from '../TourOrganizerLogin';
import NewsSeturLogin from '../SETURLogin';
import TradeLogin from '../LoginTrade';
import { useLoading } from 'hooks/useLoading';
import { navigateBasedOnProfile } from 'utils/navigateBasedOnProfile';

const HandleLoginAdmin = (): JSX.Element => {
  const form = useRef<FormikProps<FormikValues>>(null);
  const history = useHistory();
  const { appLogin } = useContext(AppContext);
  const [, setLoggedState] = useRecoilState(logged);
  const [profile, setProfile] = useState(0);
  const [modalStatus, setModalStatus] = React.useState(false);
  const [modalType, setModalType] = React.useState('');
  const { openLoading, closeLoading } = useLoading();

  const showModal = (type: string) => {
    setModalType(type);
    setModalStatus(true);
  };

  const closeModal = () => setModalStatus(false);

  const adminLoginAction = async (data: any) => {
    let authData;
    openLoading();

    try {
      const service = new AuthService();
      authData = await service.login(data);

      if (!authData || !authData.token || !authData.user) {
        throw new Error('Empty or invalid token returned!');
      }
    } catch (err) {
      if (err && typeof err === 'object') {
        const errorObject = err as { code?: string };

        if (errorObject.code === 'auth/user-disabled') {
          showModal('inactive');
        } else {
          showModal('not_found');
        }
      } else {
        showModal('not_found');
      }
      closeLoading();
      return;
    }

    const { token, user } = authData;

    const localUser = getLocalUser();
    localUser.id = user.firebaseId;
    localUser.email = user.email;
    localUser.name = user.name;
    localUser.profile.name = user.type;
    localUser.emailVerified = user.emailVerified;

    setLocalUser(localUser);
    setAccessToken(token);
    appLogin(token);
    setLoggedState(true);
    closeLoading();

    if (!user.emailVerified) {
      history.push('/email-confirmation');
      return;
    }

    history.push(navigateBasedOnProfile(localUser.profile.name));
  };

  const initialValues = { document: '', password: '' };

  const validationSchema = yup.object({
    document: yup.string().required(),
    password: yup.string().required(),
  });

  const handleProfile = () => {
    form?.current?.resetForm();

    switch (profile) {
      case 1:
        return <TourOrganizerLogin />;
      case 3:
        return <TradeLogin />;
      default:
        return <NewsSeturLogin />;
    }
  };

  return (
    <Container>
      <Content>
        <SelectProfile setProfile={setProfile} />
        <Formik
          innerRef={form}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={adminLoginAction}>
          <Form>
            <SelectLoginDiv>{handleProfile()}</SelectLoginDiv>
          </Form>
        </Formik>
      </Content>
      <Modal open={modalStatus} onClose={closeModal}>
        <SuccessModal>
          {modalType === 'inactive' ? (
            <>
              <h2>CONTA EM ANÁLISE</h2>
              <span>
                Seu cadastro já foi enviado para a administração e está em <b>fase de análise.</b> Assim que sua conta
                for aprovada, você poderá realizar seu login no portal.
              </span>
              <ButtonSection>
                <Button text="OK" onClick={() => closeModal()} />
              </ButtonSection>
            </>
          ) : (
            <>
              <h2>Conta não encontrada</h2>
              <span>
                O e-mail, CPF ou CNPJ preenchido não está vinculado a uma conta existente. Entre em Criar Conta para se
                cadastrar.
              </span>
              <ButtonSection>
                <Button text="FECHAR" onClick={() => closeModal()} />
                <Button text="CRIAR CONTA" onClick={() => history.push('/signup/admin')} />
              </ButtonSection>
            </>
          )}
        </SuccessModal>
      </Modal>
    </Container>
  );
};

export default HandleLoginAdmin;
