import React, { useRef, useState } from 'react';
import yup from 'utils/validation';
import { AskForEditData, ButtonsDiv, Container, DeleteAccountButton, Divider, FormRow, LabelData } from './styles';
import { Form, Formik, FormikProps, FormikValues, FormikHelpers } from 'formik';
import Button from 'components/Buttom/Button';
import ToggleField from 'components/ToggleField';
import ProfileImage from 'components/ProfileImage';
import ChangePasswordButton from 'features/Account/components/ChangePasswordButton';

interface Props {
  setExcludeModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  saveUserData?: (userData: any) => Promise<void>;
  userData: any;
}

const TourOrganizerAccount = (props: Props): JSX.Element => {
  const form = useRef<FormikProps<FormikValues>>(null);
  const [editing, setEditing] = useState(false);
  const [companyDataVisibility, setCompanyDataVisibility] = useState(false);
  const { setShowModal, setExcludeModal } = props || {};

  const validationSchema = yup.object({
    document: yup.string().required(),
    email: yup.string().email().required(),
    name: yup.string().required(),
    password: yup.string().min(8).required(),
    extraInfo: yup.object().shape({
      ...(companyDataVisibility && {
        cadastur: yup.string(),
        companyName: yup.string().required(),
        document: yup.string().required(),
        phone: yup.string().length(16).required(),
        tradeName: yup.string().required(),
      }),
      address: yup.object().shape({
        additionalData: yup.string(),
        city: yup.string().required(),
        neighborhood: yup.string().required(),
        number: yup.number().required(),
        state: yup.string().required(),
        street: yup.string().required(),
        zipCode: yup.string().length(9).required(),
      }),
    }),
  });

  const handleCancelButtonClick = () => {
    form?.current?.setValues(props.userData);
    setEditing(false);
  };

  const handleSaveButtonClick = async () => {
    await form?.current?.submitForm();

    if (!form?.current?.isValid) {
      return;
    }

    setEditing(false);
  };

  const onlyViewForm = (): JSX.Element => {
    return (
      <Formik
        innerRef={form}
        initialValues={props.userData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize>
        <Form>
          <FormRow id="only-view-first-row">
            <ProfileImage name="profileImage" />
            <div>
              {editing && <label>Nome Completo</label>}
              <ToggleField showField={editing} name="name" />
              {!editing && <span>Organizador de excursão</span>}
            </div>
            {!editing && <Button text={'EDITAR'} onClick={() => setEditing(true)} />}
          </FormRow>
          {companyDataVisibility && (
            <>
              <FormRow id="only-view-second-row">
                <LabelData>
                  <label>CNPJ</label>
                  <ToggleField showField={editing} name="extraInfo.document" mask="cnpj" />
                </LabelData>
                <LabelData>
                  <label>Cadastur</label>
                  <ToggleField showField={editing} name="extraInfo.cadastur" />
                </LabelData>
                <LabelData>
                  <label>Nome Fantasia</label>
                  <ToggleField showField={editing} name="extraInfo.tradeName" />
                </LabelData>
              </FormRow>
              <FormRow id="only-view-second-row">
                <LabelData>
                  <label>Razão Social</label>
                  <ToggleField showField={editing} name="extraInfo.companyName" />
                </LabelData>
              </FormRow>
            </>
          )}
          <FormRow id="only-view-fourth-row">
            <LabelData>
              <label>CPF</label>
              <ToggleField disabled={true} showField={editing} name="document" />
            </LabelData>
            <LabelData>
              <label>Telefone ou celular</label>
              <ToggleField showField={editing} name="extraInfo.phone" mask="phone" />
            </LabelData>
            <LabelData>
              <label>E-mail</label>
              <ToggleField showField={editing} name="email" />
            </LabelData>
            <LabelData>
              <label>CEP</label>
              <ToggleField showField={editing} name="extraInfo.address.zipCode" mask="zipcode" />
            </LabelData>
          </FormRow>
          <FormRow id="only-view-fourth-row">
            <LabelData>
              <label>Endereço</label>
              <ToggleField showField={editing} name="extraInfo.address.street" />
            </LabelData>
            <LabelData />
            <LabelData>
              <label>Número</label>
              <ToggleField showField={editing} name="extraInfo.address.number" />
            </LabelData>
            <LabelData>
              <label>Complemento</label>
              <ToggleField showField={editing} name="extraInfo.address.additionalData" />
            </LabelData>
          </FormRow>
          <FormRow id="only-view-fourth-row">
            <LabelData>
              <label>Bairro</label>
              <ToggleField showField={editing} name="extraInfo.address.neighborhood" />
            </LabelData>
            <LabelData>
              <label>Cidade</label>
              <ToggleField showField={editing} name="extraInfo.address.city" />
            </LabelData>
            <LabelData>
              <label>Estado</label>
              <ToggleField showField={editing} name="extraInfo.address.state" />
            </LabelData>
            <LabelData>
              <label>Senha</label>
              <ToggleField showField={editing} name="password" />
            </LabelData>
          </FormRow>
        </Form>
      </Formik>
    );
  };

  const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    if (props.saveUserData) {
      await props.saveUserData(values);
    }
  };

  return (
    <Container>
      {onlyViewForm()}
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ButtonsDiv>
          <DeleteAccountButton onClick={() => setExcludeModal && setExcludeModal(true)}>
            <img src="" alt="" />
            <span>Solicitar exclusão de conta</span>
          </DeleteAccountButton>
          <AskForEditData onClick={() => setShowModal && setShowModal(true)}>
            <img src="" alt="" />
            <span>Solicitar alteração de dado não editável</span>
          </AskForEditData>
          <ChangePasswordButton />
        </ButtonsDiv>
        {editing && (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button text={'Cancelar'} onClick={handleCancelButtonClick} />
            <Button text={'Salvar'} type="submit" onClick={handleSaveButtonClick} />
          </div>
        )}
      </div>
    </Container>
  );
};

export default TourOrganizerAccount;
