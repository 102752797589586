import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  padding: 0 50px;

  .MessageError {
    position: absolute;
    top: 130px;
  }

  @media (max-width: 980px) {
    padding: 0 16px;

    .MessageError {
      top: unset;
      bottom: -10px;
    }
  }
`;

export const MainContentTitle = styled.h1`
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  color: #272727;
`;

export const FormList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;
  list-style-type: none;

  div {
    color: #f00;
  }

  @media (max-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const FormListRow = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  margin-bottom: 30px;

  &.col-2 {
    grid-column: span 2;
  }

  &.col-3 {
    grid-column: span 3;
  }

  &.col-4 {
    grid-column: span 4;
  }

  &.uploadImage h2 {
    &::after {
      content: '*';
      color: #f00;
    }
  }

  label {
    font-size: 16px;
    font-weight: 500;
    color: #272727;
    gap: 8px;

    &.with-tooltip {
      display: flex;
      align-items: center;
    }

    span {
      margin-left: 5px;
      color: #f00;
    }

    img {
      margin-left: 5px;
    }
  }

  input {
    height: 40px;
    padding: 0px 10px;
    border-radius: 18px;
    border: 1px solid #9a9a9a;
    gap: 10px;
  }

  textarea {
    height: 76px;
    padding: 8px 10px 8px 10px;
    border-radius: 18px;
    border: 1px solid #ccc;
    gap: 10px;
    resize: none;
  }
`;

export const FormListButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 30px;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  img {
    border-radius: 18px;
  }

  @media (max-width: 960px) {
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
  }
`;
