import React, { useRef, useState } from 'react';
import { BusinessOwner, Container, DeleteAccountButton, Divider, FormRow, LabelData, RequestButton } from './styles';
import Button from 'components/Buttom/Button';
import { Form, Formik, FormikProps, FormikValues, FormikHelpers } from 'formik';
import yup from 'utils/validation';
import ToggleField from 'components/ToggleField';
import ProfileImage from 'components/ProfileImage';
import ArrayField from 'components/ArrayField';
import ChangePasswordButton from 'features/Account/components/ChangePasswordButton';

interface Props {
  setExcludeModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  saveUserData?: (userData: any) => Promise<void>;
  userData: any;
}

const TradeAccount = (props: Props): JSX.Element => {
  const form = useRef<FormikProps<FormikValues>>(null);
  const [editing, setEditing] = useState(false);
  const { setExcludeModal, setShowModal } = props || {};

  const getEditValidationSchema = () => {
    return yup.object().shape({
      email: yup.string().email().required(),
      extraInfo: yup.object().shape({
        businessOwner: yup.array().of(yup.string()).required(),
        businessRepresentative: yup.string().required(),
        phone: yup.string().required(),
      }),
    });
  };

  const validationSchema = getEditValidationSchema();

  const handleCancelButtonClick = () => {
    form?.current?.setValues(props.userData);
    setEditing(false);
  };

  const handleSaveButtonClick = async () => {
    await form?.current?.submitForm();

    if (!form?.current?.isValid) {
      return;
    }

    setEditing(false);
  };

  const onlyViewForm = (): JSX.Element => {
    const businessOwner = props.userData.extraInfo?.businessOwner || [];

    return (
      <Formik
        innerRef={form}
        initialValues={props.userData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize>
        <Form>
          <FormRow id="only-view-first-row">
            <div className="profileImage">
              <ProfileImage name="profileImage" />
            </div>
            <div className="businessRepresentative">
              <FormRow key={0} id={`only-view-owner-row-${0}`}>
                <LabelData>
                  <label>Nome Completo do Proprietário</label>
                  {editing ? (
                    <BusinessOwner>
                      <ArrayField name="extraInfo.businessOwner" />
                    </BusinessOwner>
                  ) : (
                    businessOwner.map((owner: string, index: number) => (
                      <ToggleField key={index} showField={editing} name={`extraInfo.businessOwner[${index}]`} />
                    ))
                  )}
                </LabelData>
              </FormRow>
            </div>
            {!editing && <Button text={'EDITAR'} onClick={() => setEditing(true)} />}
          </FormRow>
          <FormRow id="only-view-second-row">
            <div className="businessOwner">
              <FormRow>
                <LabelData>
                  <label>Nome completo do Responsável comercial</label>
                  <ToggleField showField={editing} name="extraInfo.businessRepresentative" />
                </LabelData>
              </FormRow>
            </div>
            <div className="documentField">
              <LabelData>
                <label>CPF do Primeiro Proprietario</label>
                <ToggleField disabled={true} showField={editing} name="document" />
              </LabelData>
            </div>
          </FormRow>
          <FormRow id="only-view-second-row">
            <LabelData>
              <label>Contato</label>
              <ToggleField showField={editing} name="extraInfo.phone" mask="phone" />
            </LabelData>
            <LabelData>
              <label>E-mail</label>
              <ToggleField disabled={true} showField={editing} name="email" />
            </LabelData>
          </FormRow>
        </Form>
      </Formik>
    );
  };

  const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    if (props.saveUserData) {
      values.name = values?.extraInfo?.businessOwner?.[0] || values.name;
      await props.saveUserData(values);
    }
  };

  return (
    <Container>
      {onlyViewForm()}
      {editing && (
        <div className="editButtons">
          <Button text={'Cancelar'} onClick={handleCancelButtonClick} />
          <Button text={'Salvar'} type="submit" onClick={handleSaveButtonClick} />
        </div>
      )}
      <Divider />
      <RequestButton>
        {/* <DeleteAccountButton onClick={() => setExcludeModal?.(true)}>
          <img src="" alt="" />
          <span>Solicitar exclusão de conta</span>
        </DeleteAccountButton> */}
        <ChangePasswordButton />
      </RequestButton>
    </Container>
  );
};

export default TradeAccount;
