import { businessFormInitialValues } from './../utils';
import Yup from 'utils/validation';

export const toursInitialValues = {
  ...businessFormInitialValues,
  tour: {
    id: '',
    attractions: '',
    equipments: '',
    businessId: 0,
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
  },
};

export const toursValidationSchema = Yup.object().shape({
  id: Yup.number(),
  businessRepresentative: Yup.string().required(),
  businessOwner: Yup.array().of(Yup.string().required()),
  businessType: Yup.string().min(1).required(),
  description: Yup.string().required(),
  document: Yup.string().length(18).required(),
  experiences: Yup.array().min(1).of(Yup.string()).required(),
  googleMapsLink: Yup.string().required(),
  tradeEmail: Yup.string().email(),
  tradeName: Yup.string().required(),
  title: Yup.string().required(),
  phone: Yup.string().required(),
  image: Yup.array().min(1).required(),
  address: Yup.object().shape({
    id: Yup.number(),
    city: Yup.string().required(),
    neighborhood: Yup.string().required(),
    number: Yup.string().required(),
    state: Yup.string().required(),
    street: Yup.string().required(),
    zipCode: Yup.string().required(),
  }),
  images: Yup.array().min(1).required(),
  tour: Yup.object().shape({
    id: Yup.number(),
    attractions: Yup.string(),
    equipments: Yup.string(),
  }),
});
