import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { useLoading } from 'hooks/useLoading';
import IBanner from 'interfaces/IBanner';
import IDestination from 'interfaces/IDestination';
import BannerService from 'services/BannerService';
import DestinationService from 'services/DestinationService';
import ImageService from 'services/ImageService';
import editIcon from 'assets/images/editIcon.svg';
import trashIcon from 'assets/images/trashIcon.svg';
import dragIcon from 'assets/images/dragIcon.svg';
import {
  SettingsList,
  SettingsListItem,
  SettingsListItemButton,
  SettingsListItemButtonList,
  SettingsListItemTitle,
} from 'components/List/styles';
import Heading from 'components/Heading';
import Button from 'components/Button';
import Input from 'components/Input/Input';
import ConfirmationModal from 'components/ConfirmationModal';
import MessageModal from 'common/MessageModal';
import {
  AddImageButton,
  BannerImage,
  CarouselHeader,
  CloseImageButton,
  Paragraph,
  Section,
  SettingsListContainer,
} from './styles';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const Home = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [currentBanners, setCurrentBanners] = useState<IBanner[]>([]);
  const [destinations, setDestinations] = useState<IDestination[]>([]);
  const [editingBanners, setEditingBanners] = useState(false);
  const [fileIndex, setFileIndex] = useState(-1);
  const [files, setFiles] = useState<{ [key: string]: File | undefined }>({});
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [targetId, setTargetId] = useState(0);
  const [messageModal, setMessageModal] = useState(false);
  const [messageModalText, setMessageModalText] = useState('');

  const inputFile = useRef<any>(null);

  const service = {
    banner: new BannerService(),
    destination: new DestinationService(),
    file: new ImageService(),
  };

  useEffect(() => {
    openLoading();
    const fetchData = [fetchBanners(), fetchDestinations()];
    Promise.all(fetchData).finally(() => closeLoading());
  }, []);

  const fetchBanners = async () => {
    const data = await service.banner.findAll();
    data.sort((a, b) => b.id - a.id);

    setBanners(data);
    setCurrentBanners(JSON.parse(JSON.stringify(data)));
  };

  const saveBannersEditing = async () => {
    const emptyImage = banners.some(({ image }) => !image);

    if (emptyImage) {
      showModal('Selecione uma imagem para todos os itens do carrossel!');
      return;
    }

    openLoading();
    for (const banner of banners.reverse()) {
      let image = banner.image;
      const file = files[image];
      if (file) {
        image = await service.file.upload(file);
      }

      await service.banner.create({ ...banner, image });
    }

    const deleteBanners = currentBanners.map(banner => {
      return service.banner.remove(banner.id);
    });

    await Promise.all(deleteBanners);

    await fetchBanners();
    setEditingBanners(false);
    closeLoading();
  };

  const cancelBannersEditing = () => {
    setBanners(JSON.parse(JSON.stringify(currentBanners)));
    setEditingBanners(false);
  };

  const addBanner = () => {
    setEditingBanners(true);
    const newBanner = { id: 0, description: '', image: '', link: '', title: '' };
    setBanners(current => [...current, newBanner]);
  };

  const deleteBanner = () => {
    setEditingBanners(true);
    setBanners(
      banners.filter(banner => {
        return banner.id !== targetId;
      })
    );
  };

  const fetchDestinations = async () => {
    const data = await service.destination.findAll();
    setDestinations(data);
  };

  const showModal = (message: string) => {
    setMessageModalText(message);
    setMessageModal(true);
  };

  const handleInputFileChange = (event: any) => {
    if (!event?.target?.files?.[0]) {
      return;
    }

    const file = event.target.files[0];
    const objectUrl = URL.createObjectURL(file);

    setFiles({ ...files, [objectUrl]: file });
    updateBannerImage(objectUrl);
  };

  const updateBannerImage = (image: string, current = fileIndex) => {
    const updateBanners = banners.map((banner, index) => {
      if (current === index) {
        return { ...banner, image };
      }
      return { ...banner };
    });

    setBanners(updateBanners);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(banners);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setBanners(items);
  };

  const handleInputTitleChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setEditingBanners(true);
    const updatedBanners = banners.map(banner => {
      if (banner.id === id) {
        return { ...banner, title: event.target.value };
      }
      return banner;
    });

    setBanners(updatedBanners);
  };

  const handleInputURLChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setEditingBanners(true);
    const updatedBanners = banners.map(banner => {
      if (banner.id === id) {
        return { ...banner, link: event.target.value };
      }
      return banner;
    });
    setBanners(updatedBanners);
  };

  return (
    <Box>
      <Section>
        <CarouselHeader>
          <Heading>Carrossel</Heading>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" columnSpacing={4}>
            <Grid item>
              {!editingBanners && (
                <Button buttonType="secondary" onClick={() => setEditingBanners(true)}>
                  Editar
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button onClick={addBanner}>Adicionar</Button>
            </Grid>
          </Grid>
        </CarouselHeader>
        <Paragraph>
          Para garantir a qualidade da visualização, as imagens do carrossel precisam ter dimensão mínima de 1366 x 600
          pixels (a ordem das imagens no carrossel será a mesma mostrada abaixo)
        </Paragraph>
        <SettingsListContainer>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="carousel-list" type="list" direction="vertical" isDropDisabled={!editingBanners}>
              {provided => (
                <SettingsList className="carousel-list" ref={provided.innerRef} {...provided.droppableProps}>
                  {banners.map((banner, index) => (
                    <Draggable
                      key={index}
                      draggableId={index.toString()}
                      index={index}
                      isDragDisabled={!editingBanners}>
                      {provided => (
                        <SettingsListItem
                          key={index}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          {editingBanners && (
                            <SettingsListItemTitle>
                              <div>
                                <img draggable={false} src={dragIcon} alt="icon for drag item" />
                              </div>
                            </SettingsListItemTitle>
                          )}
                          <SettingsListItemTitle>
                            <BannerImage
                              style={{
                                ...(banner.image && {
                                  background: `url(${banner.image}) center center / cover no-repeat`,
                                }),
                                ...(!banner.image && { backgroundColor: '#d9d9d9' }),
                              }}>
                              {editingBanners &&
                                (!banner.image ? (
                                  <div className="add-image-button-container">
                                    <AddImageButton
                                      onClick={() => {
                                        inputFile?.current?.click();
                                        setFileIndex(index);
                                      }}>
                                      +
                                    </AddImageButton>
                                  </div>
                                ) : (
                                  <CloseImageButton onClick={() => updateBannerImage('', index)}>X</CloseImageButton>
                                ))}
                            </BannerImage>
                          </SettingsListItemTitle>
                          <SettingsListItemTitle className="input-box">
                            {editingBanners ? (
                              <Input
                                type="text"
                                defaultValue={banner.title}
                                onChange={e => handleInputTitleChange(e, banner.id)}
                                className="input-title"
                                placeholder="Digite o título do item"
                                value={banner.title}
                              />
                            ) : (
                              banner.title
                            )}
                          </SettingsListItemTitle>
                          <SettingsListItemTitle className="input-box">
                            {editingBanners ? (
                              <Input
                                type="text"
                                defaultValue={banner.link}
                                onChange={e => handleInputURLChange(e, banner.id)}
                                className="input-link"
                                placeholder="Digite o link de redirecionamento"
                                value={banner.link}
                              />
                            ) : (
                              banner.link
                            )}
                          </SettingsListItemTitle>
                          <SettingsListItemButtonList>
                            <SettingsListItemButton
                              onClick={() => {
                                setConfirmDeletion(true);
                                setTargetId(banner.id);
                              }}>
                              <img src={trashIcon} alt="icon for trash item" />
                            </SettingsListItemButton>
                          </SettingsListItemButtonList>
                        </SettingsListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </SettingsList>
              )}
            </Droppable>
          </DragDropContext>
        </SettingsListContainer>
        {editingBanners && (
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" columnSpacing={4}>
            <Grid item>
              <Button buttonType="secondary" onClick={cancelBannersEditing}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={saveBannersEditing}>Salvar</Button>
            </Grid>
          </Grid>
        )}
      </Section>
      <Section>
        <Heading>Lista de Destinos</Heading>
        <SettingsListContainer>
          <SettingsList>
            {destinations.map((destination, index) => (
              <SettingsListItem key={index}>
                <SettingsListItemTitle>{destination.title}</SettingsListItemTitle>
                <SettingsListItemButtonList>
                  <Link to={`/admin/destination/${destination.id}`}>
                    <SettingsListItemButton>
                      <img src={editIcon} alt="icon for edit item" />
                    </SettingsListItemButton>
                  </Link>
                </SettingsListItemButtonList>
              </SettingsListItem>
            ))}
          </SettingsList>
        </SettingsListContainer>
      </Section>
      <input type="file" ref={inputFile} style={{ display: 'none' }} onChange={handleInputFileChange} />
      <ConfirmationModal
        open={confirmDeletion}
        title="EXCLUSÃO DE ITEM"
        message="Você tem certeza de que deseja excluir esse item?"
        onCancel={() => setConfirmDeletion(false)}
        onConfirm={() => {
          setConfirmDeletion(false);
          deleteBanner();
        }}
      />
      <MessageModal
        title="Aviso"
        isOpen={messageModal}
        message={messageModalText}
        onClose={() => setMessageModal(false)}
      />
    </Box>
  );
};

export default Home;
